@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer base {
    h1, h2, h3 {
        @apply text-gray-800 dark:text-gray-300;
    }

    #navbar {
        @apply transition-all ease-in-out duration-500;
    }

}

@layer components {
    
    .square {
        @apply bg-white dark:bg-gray-700;
    }
    
    .entry-content a {
        @apply text-primary-600 dark:text-primary-400 border-b-2 border-primary-100 dark:border-gray-700 hover:text-primary-700 dark:hover:text-primary-300 hover:border-primary-700 dark:hover:border-primary-300 transition-colors ease-in-out duration-300;
    }

    .entry-content li {
        @apply ml-7;
    }
}

@layer utilities {
    
}

@layer variants {
    
}
